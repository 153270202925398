<template>
    <div
        ref="target"
        class="input-group-container sleep-option flex-full min-w-[280px]"
        :class="{ 'has-value': selected?.id?.length }"
    >
        <label for="form-input-sleep" class="input-group-title has-icon">{{ title }}</label>
        <div class="input-group" :class="{ error: validation ? validation.id.$error : false }">
            <div class="input-icon-prepend hide-desktop mt-1">
                <NuxtIcon name="ri:hotel-bed-fill" />
            </div>
            <div class="input-icon-append">
                <NuxtIcon name="ri:arrow-down-s-line" />
            </div>
            <input
                :id="`form-input-${name}`"
                type="text"
                :name="name"
                :value="selected ? selected.name : null"
                :placeholder="placeholder"
                :data-tooltip="tooltip"
                class="tooltip-handle"
                readonly
                @click="toggleSleepDropdown()"
            />
            <div class="tooltip">{{ tooltip }}</div>
            <div v-if="validation && validation.id.$error && validation.id.required.$invalid" class="error-tooltip">
                {{ validation.id.required.$message }}
            </div>
            <div v-show="dropdown" class="dropdown-list">
                <template v-for="(option, key) in options" :key="option.id">
                    <div
                        class="dropdown-list-item"
                        :class="{ active: selected && selected.id === option.id }"
                        @click="setSleepOption(key)"
                    >
                        {{ option.name }} ({{ option.additional }})
                    </div>
                </template>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
const props: any = defineProps({
    name: { type: String, required: true, default: 'Sleep' },
    title: { type: String, required: true, default: 'Title' },
    tooltip: { type: String, required: true, default: 'Tooltip' },
    placeholder: { type: String, required: true, default: 'Placeholder' },
    options: { type: Object, required: true, default: () => ({}) },
    value: { type: Object, required: false, default: () => ({ name: null }) },
    validation: { type: Object, required: false, default: null },
});

// Constant variable for initialising emit events
const emit: any = defineEmits(['update:value']);

// Component Ref
const target = ref(null);

// Dropdown Status Ref
const dropdown: any = ref(false);

// Selected Object
const selected: any = ref();

onMounted(() => {
    selected.value = props.value;
});

// Outside click event
onClickOutside(target, () => (dropdown.value = false));

const toggleSleepDropdown = (bool: any = null) => {
    if (bool === null) {
        dropdown.value = !dropdown.value;
    } else {
        dropdown.value = bool;
    }
};

const setSleepOption = (type: string) => {
    selected.value = props.options[type];
    emit('update:value', props.options[type]);
    toggleSleepDropdown(false);
};
</script>
